.dashboardPage{width: $full-width;height: 100vh;float: left;background-color: $page_bg;position: relative;}
.dashboardHeaderContentFooterSidebar{width: calc($full-width - 300px);height: 100%;float: left;position: relative;z-index: 5;}
.dashboardHeaderContent{width: $full-width;height: 100%;float: left;position: relative;z-index: 5;overflow-y: auto;padding-bottom: 62px;overflow-x: hidden;}

.dashboardPageBigHeader{width: $full-width;float: left;background-color: $white;padding: 50px 30px;border-bottom: 1px solid  $dashboard-border-bg;}
.dashboardPageBigHeaderInner{width: $full-width;float: left;}
.dashboardPageBigHeaderDetails{width: $full-width;float: left;display: flex;align-items: center;justify-content: space-between;flex-direction: row;}
.dashboardPageBigHeaderUser{width: 60%;float: left;display: flex;align-items: center;flex-direction: row;position: relative;padding-left: 80px;}
.dashboardPageBigHeaderUserAvatar{width: 65px;height: 100%;float: left;position: absolute;left: 0px;top: 0px;display: flex;align-items: center;}
.dashboardPageBigHeaderUserAvatarImage{width: 65px;height: 65px;float: left;border-radius: 50%;overflow: hidden;}
.dashboardPageBigHeaderUserAvatarImg{width: $full-width;height: $full-width;float: left;}
.dashboardPageBigHeaderUserHeadingDescription{width: $full-width;float: left;display: flex;flex-direction: column;gap: 5px;}
.dashboardPageBigHeaderUserHeading{width: $full-width;float: left;}
.dashboardPageBigHeaderUserHeadingTxt{float: left;font-size: 2.2em;font-family: font("semibold");text-transform: capitalize;color: $blue-primary;}
.dashboardPageBigHeaderUserDescription{width: $full-width;float: left;position: relative;padding: 0px;display: flex;align-items: center;}
.dashboardPageBigHeaderUserDescription.hasIcon{padding: 0px 0px 0px 22px;}
.dashboardPageBigHeaderUserDescriptionIcon{float: left;position: absolute;left: 0px;top: 0px;height: $full-width;display: flex;align-items: center;}
.dashboardPageBigHeaderUserDescriptionIco{color: $dashboard-grey;float: left;font-size: 1em;}
.dashboardPageBigHeaderUserDescriptionTxt{color: $dashboard-grey;float: left;font-size: 0.9em;font-family: font("medium");margin-top: 1px;}

.dashboardPageDefaultHeader{width: $full-width;float: left;background-color: $white;padding: 30px 30px;border-bottom: 1px solid  $dashboard-border-bg;}
.dashboardPageDefaultHeaderInner{width: $full-width;float: left;}
.dashboardPageDefaultHeaderDetails{width: $full-width;float: left;display: flex;align-items: center;justify-content: space-between;flex-direction: row;}

.dashboardPageDefaultHeaderContent{width: 55%;float: left;display: flex;flex-direction: column;gap: 5px;}
.dashboardPageDefaultHeaderContentHeading{width: $full-width;float: left;}
.dashboardPageDefaultHeaderContentHeadingTxt{float: left;font-size: 1.7em;font-family: font("semibold");text-transform: capitalize;color: $blue-primary;}
.dashboardPageDefaultHeaderContentDescription{width: $full-width;float: left;position: relative;padding: 0px;display: flex;align-items: center;}
.dashboardPageDefaultHeaderContentDescription.hasIcon{padding: 0px 0px 0px 22px;}
.dashboardPageDefaultHeaderContentDescriptionIcon{float: left;position: absolute;left: 0px;top: 0px;height: $full-width;display: flex;align-items: center;}
.dashboardPageDefaultHeaderContentDescriptionIco{color: $dashboard-grey;float: left;font-size: 1em;}
.dashboardPageDefaultHeaderContentDescriptionTxt{color: $dashboard-grey;float: left;font-size: 0.9em;font-family: font("medium");margin-top: 1px;}

.dashboardPageDetailsHeaderContent{width: 55%;float: left;display: flex;flex-direction: column;gap: 10px;}
.dashboardPageDetailsHeaderContentBackEntity{width: $full-width;float: left;display: flex;align-items: center;flex-direction: row;}
.dashboardPageDetailsHeaderContentBack{float: left;margin-right: 10px;cursor: pointer;}
.dashboardPageDetailsHeaderContentBackIco{float: left;color: $bubble-dark;font-size: 1.2em;}
.dashboardPageDetailsHeaderContentBackIco:hover{@include txtColorHoverAnim();color: $bubble-dark-transparent-dark;}
.dashboardPageDetailsHeaderContentEntity{float: left;}
.dashboardPageDetailsHeaderContentEntityTxt{float: left;font-size: 1em;color: $text-description;text-transform: capitalize;font-family: font("medium");}
.dashboardPageDetailsHeaderContentHeadingImageText{width: $full-width;float: left;position: relative;}
.dashboardPageDetailsHeaderContentHeadingImageContainer{width: 60px;height: 60px;float: left;background-color: $dashboard-border-bg;border-radius: 5px;overflow: hidden;padding: 5px;position: absolute;left: 0px;top: 0px;}
.dashboardPageDetailsHeaderContentHeadingImageInner{width: $full-width;height: $full-width;float: left;@include flexCenter();}
.dashboardPageDetailsHeaderContentHeadingImage{max-width: $full-width;max-height: $full-width;float: left;overflow: hidden;}
.dashboardPageDetailsHeaderContentHeadingImg{max-width: $full-width;max-height: $full-width;float: left;}
.dashboardPageDetailsHeaderContentHeadingDescription{width: $full-width;float: left;height: 60px;display: flex;justify-content: center;flex-direction: column;}
.dashboardPageDetailsHeaderContentHeadingDescription.hasPadding{padding-left: 80px;}
.dashboardPageDetailsHeaderContentHeadingText{width: $full-width;float: left;}
.dashboardPageDetailsHeaderContentHeadingTxt{width: $full-width;float: left;font-size: 1.4em;font-family: font("semibold");text-transform: capitalize;color: $blue-primary;@include singleLine();}
.dashboardPageDetailsHeaderContentDescription{width: $full-width;float: left;margin-top: 5px;}
.dashboardPageDetailsHeaderContentDescriptionTxt{width: $full-width;color: $dashboard-grey;float: left;font-size: 0.9em;font-family: font("medium");@include singleLine();text-transform: capitalize;}

.dashboardPageHeaderActions{width: 45%;float: left;display: flex;justify-content: flex-end;gap: 12px;}

.dashboardPageStats{width: $full-width;float: left;padding: 30px;display: flex;flex-direction: row;gap: 30px;}
.dashboardPageStatSingle{width: 25%;float: left;}
.dashboardHomeStats{width: $full-width;float: left;display: flex;flex-direction: column;justify-content: center;align-items: center;}
.dashboardHomeStatsNumberLable{width: $full-width;float: left;}
.dashboardHomeStatsNumber{width: $full-width;float: left;}
.dashboardHomeStatsNumberTxt{width: $full-width;float: left;text-align: center;font-family: font("bold");font-size: 4em;}
.dashboardHomeStatsNumberTxt.blue{color: $dashboard-sky-blue;}
.dashboardHomeStatsNumberTxt.red{color: $dashboard-red;}
.dashboardHomeStatsNumberTxt.yellow{color: $dashboard-yellow;}
.dashboardHomeStatsNumberTxt.green{color: $dashboard-green;}
.dashboardHomeStatsLable{width: $full-width;float: left;margin-bottom: 20px;}
.dashboardHomeStatsLableTxt{width: $full-width;float: left;text-align: center;text-transform: capitalize;font-family: font("semibold");}
.dashboardHomeStatsLableTxt.blue{color: $dashboard-sky-blue-dark;}
.dashboardHomeStatsLableTxt.red{color: $dashboard-red-dark;}
.dashboardHomeStatsLableTxt.yellow{color: $dashboard-yellow-dark;}
.dashboardHomeStatsLableTxt.green{color: $dashboard-green-dark;}
.dashboardHomeStatsSecondaryStats{width: $full-width;float: left;margin-top: 25px;display: flex;justify-content: center;padding-bottom: 25px;}
.dashboardHomeStatsSecondaryStatsContent{float: left;}
.dashboardHomeStatsSecondaryStatLable{float: left;font-size: 0.9em;text-transform: capitalize;color: $dashboard-grey;font-family: font("medium");}
.dashboardHomeStatsSecondaryStatCount{float: left;font-size: 0.9em;text-transform: capitalize;color: $dashboard-grey;font-family: font("semibold");margin-left: 5px;}

.dashboardBasicRequestListItem{width: $full-width;float: left;position: relative;padding: 15px 25px;border-bottom: 1px solid $dashboard-border-bg;cursor: pointer;}
.dashboardBasicRequestListItem:last-child{border: 0px;}
.dashboardBasicRequestListItem.hasAction{padding-right: 55px;}
.dashboardBasicRequestListItem:hover{background-color: $dashboard-border-bg;}
.dashboardBasicRequestListItemContent{width: $full-width;float: left;}
.dashboardBasicRequestListItemHeading{width: $full-width;float: left;}
.dashboardBasicRequestListItemHeadingTxt{width: $full-width;float: left;@include listHeading();}
.dashboardBasicRequestListItemInfo{width: $full-width;float: left;display: flex;align-items: center;flex-direction: row;gap: 30px;margin-top: 10px;}
.dashboardBasicRequestListItemInfoStep{float: left;display: flex;flex-direction: row;gap: 10px;align-items: center;}
.dashboardBasicRequestListItemInfoStepIcon{float: left;}
.dashboardBasicRequestListItemInfoStepIco{float: left;@include listIcon();}
.dashboardBasicRequestListItemInfoStepLable{float: left;}
.dashboardBasicRequestListItemInfoStepLableTxt{float: left;@include listDescription();}
.dashboardBasicRequestListItemAction{width: 30px;float: left;position: absolute;right: 25px;top: 0px;height: $full-width;display: flex;align-items: center;justify-content: flex-end;}
.dashboardBasicRequestListItemActionIcon{float: left;}
.dashboardBasicRequestListItemActionIco{float: left;@include listActionIcon();}

.dashboardBasicActivitiesList{width: $full-width;float: left;position: relative;padding-left: 50px;}
.dashboardBasicActivitiesTimeline{width: 40px;height: $full-width;float: left;position: absolute;left: 0px;top: 0px;display: flex;justify-content: center;}
.dashboardBasicActivitiesTimelineLine{width: 2px;height: $full-width;float: left;background-color: $border_grey;}
.dashboardBasicActivitiesListMain{width: $full-width;float: left;padding-left: 5px;display: flex;flex-direction: column;gap: 25px;}
.dashboardBasicActivitiesListItem{width: $full-width;float: left;position: relative;min-height: 50px;}
.dashboardBasicActivitiesListItemTimeline{width: 50px;height: 50px;float: left;position: absolute;left: -60px;top: 0px;background-color: $blue-primary;border: 5px solid $white;z-index: 99;border-radius: 50%;overflow: hidden;@include flexCenter();}
.dashboardBasicActivitiesListItemTimelineInner{float: left;}
.dashboardBasicActivitiesListItemTimelineIcon{float: left;}
.dashboardBasicActivitiesListItemTimelineIco{color: $white;font-size: 1.2em;float: left;}
.dashboardBasicActivitiesListItemInfo{width: $full-width;float: left;display: flex;align-items: center;min-height: 50px;}
.dashboardBasicActivitiesListItemInfoInner{width: $full-width;float: left;}
.dashboardBasicActivitiesListItemInfoHeading{width: $full-width;float: left;}
.dashboardBasicActivitiesListItemInfoHeadingTxt{width: $full-width;float: left;@include singleLine();display: flex;flex-direction: row;gap: 6px;}
.dashboardBasicActivitiesListItemInfoHeadingTxtBold{float: left;@include activityHeadingBold();}
.dashboardBasicActivitiesListItemInfoHeadingTxtNormal{float: left;@include activityHeadingRegular();}
.dashboardBasicActivitiesListItemInfoContent{width: $full-width;float: left;display: flex;flex-direction: row;gap: 20px;margin-top: 8px;align-items: center;}
.dashboardBasicActivitiesListItemInfoDateTime{float: left;}
.dashboardBasicActivitiesListItemInfoDateTimeTxt{float: left;@include activityListTime();}
.dashboardBasicActivitiesListItemInfoSeperator{width: 4px;height: 4px;float: left;}
.dashboardBasicActivitiesListItemInfoSeperatorInner{float: left;width: $full-width;height: $full-width;float: left;border-radius: 50%;background-color: $blue-primary;overflow: hidden;}
.dashboardBasicActivitiesListItemInfoLink{float: left;}
.dashboardBasicActivitiesListItemInfoLinkTxt{float: left;@include listLink()}
.dashboardBasicActivitiesListItemInfoLinkTxt:hover{@include listLinkHover()}

.addUserFormPreviewContainer{width: $full-width;float: left;display: flex;flex-direction: row;}
.addUserFormContainer{padding-right: 30px;border-right: 2px solid $dashboard-bordered-card-border;}
.addUserPreviewContainer{width: 40%;min-width: 350px;float: left;display: flex;align-items: center;padding-left: 25px;}
.addUserPreviewMain{width: $full-width;float: left;display: flex;flex-direction: column;gap: 15px;}
.addUserPreviewVisual{width: $full-width;float: left;display: flex;justify-content: center;}
.addUserPreviewVisualMain{width: 175px;height: 175px;float: left;}
.addUserPreviewVisualBubble{width: $full-width;height: $full-width;float: left;position: relative;border: 3px solid $page_bg;border-radius: 50%;overflow: hidden;}
.addUserPreviewVisualBubbleImageContainer{width: $full-width;height: $full-width;float: left;}
.addUserPreviewVisualBubbleImage{width: $full-width;height: $full-width;float: left;}
.addUserPreviewVisualBubbleEdit{position: absolute;bottom: 0px;left: 0px;width: $full-width;}
.addUserPreviewVisualBubbleEditDefault{float: left;width: $full-width;display: flex;justify-content: center;background: rgba(0, 0, 0, 0.55);padding: 7px 0px 6px;}
.addUserPreviewVisualBubbleEditDefault:hover{background: rgba(79, 70, 229, 0.9);cursor: pointer;@include bgColorHoverAnim();}
.addUserPreviewVisualBubbleEditDefaultTxt{color: $white;text-transform: uppercase;}
.addUserPreviewVisualBubbleEditSave{float: left;width: $full-width;display: flex;justify-content: center;background: rgba(79, 70, 229, 0.7);padding: 7px 0px 6px;}
.addUserPreviewVisualBubbleEditSave:hover{background: rgba(79, 70, 229, 1);cursor: pointer;@include bgColorHoverAnim();}
.addUserPreviewVisualBubbleEditSaveTxt{color: $white;text-transform: uppercase;}

.selectAvatarSection{width: 325px;float: left;display: flex;flex-direction: column;align-items: center;position: relative;}
.selectAvatarSectionArrow{width: $full-width;float: left;display: flex;justify-content: center;position: absolute;top: -15px;left: 0px;}
.selectAvatarSectionArrowMain{float: left;font-size: 1.4em;rotate: (-90deg);color: $page_bg;}
.selectAvatarSectionList{width: $full-width;float: left;display: flex;flex-direction: row;flex-wrap: wrap;gap: 3px;background-color: $page_bg;border: 3px solid $page_bg;border-radius: 20px;overflow: hidden;}
.selectAvatarSectionListItem{width: 104.3px;height: 104.3px;float: left;background-color: $white;}
.selectAvatarSectionListItemImage{width: $full-width;height: $full-width;float: left;position: relative;}
.selectAvatarSectionListItemImg{width: $full-width;height: $full-width;float: left;}
.selectedAvatarItem{width: $full-width;height: $full-width;float: left;position: absolute;top: 0px;left: 0px;background-color: rgba(79, 70, 229, 0);display: flex;justify-content: center;align-items: center;cursor: pointer;}
.selectedAvatarItem:hover{background-color: rgba(79, 70, 229, 0.85);@include bgColorHoverAnim();}
.selectedAvatarItem:hover .selectedAvatarIcon{opacity: 1;@include opacityColorHoverAnim();}
.selectedAvatarIcon{float: left;opacity: 0;}
.selectedAvatarItem.selected{background-color: rgba(79, 70, 229, 0.85);}
.selectedAvatarItem.selected .selectedAvatarIcon{opacity: 1;}
.selectedAvatarIco{color: #fff;font-size: 2em;float: left;}

.addUserPreviewContent{width: $full-width;float: left;}
.addUserPreviewContent.hasMargin{margin-top: 0px;}
.addUserPreviewContentNameRole{width: $full-width;float: left;display: flex;align-items: center;flex-direction: column;}
.addUserPreviewContentName{float: left;max-width: $full-width;}
.addUserPreviewContentNameTxt{float: left;max-width: $full-width;text-align: center;font-size: 1.2em;font-family: font("semibold");text-transform: capitalize;color: $blue-primary;@include singleLine();}
.addUserPreviewContentRole{float: left;}
.addUserPreviewContentRoleTxt{color: $dashboard-grey;float: left;font-size: 0.9em;font-family: font("regular");margin-top: 2px;text-transform: capitalize;}
.addUserPreviewContentEmailPhone{width: $full-width;float: left;display: flex;align-items: center;flex-direction: column;margin-top: 10px;}
.addUserPreviewContentEmail{float: left;max-width: $full-width;}
.addUserPreviewContentEmailTxt{max-width: $full-width;color: $dashboard-grey;float: left;font-size: 1em;font-family: font("regular");@include singleLine();}
.addUserPreviewContentPhone{float: left;}
.addUserPreviewContentPhoneTxt{color: $dashboard-grey;float: left;font-size: 1em;font-family: font("regular");margin-top: 4px;}

.sectionFieldItemsRow{width: $full-width;float: left;padding-bottom: 15px;border-bottom: 1px solid $dashboard-border-bg;display: flex;flex-direction: row;gap: 25px;margin-top: 15px;}
.sectionFieldItemsRow:first-child{margin: 0px;}
.sectionFieldItemsRow:last-child{border: 0px;padding: 0px;}
.sectionFieldItem{width: $full-width;float: left;position: relative;}
.sectionFieldItemIconContainer{width: 35px;height: $full-width;float: left;position: absolute;left: 0px;top: 0px;}
.sectionFieldItemIconInner{width: $full-width;float: left;padding-top: 13px;}
.sectionFieldItemIco{color: $bubble-primary;float: left;font-size: 1.2em;}
.sectionFieldItemLableContent{width: $full-width;float: left;display: flex;flex-direction: column;gap: 7px;}
.sectionFieldItemLableContent.hasIcon{padding-left: 35px;}
.sectionFieldItemLableContent.isEditable{padding-right: 35px;}
.sectionFieldItemLable{width: $full-width;float: left;}
.sectionFieldItemLableTxt{float: left;font-family: font("medium");color: $dashboard-grey;font-size: 0.85em;text-transform: capitalize;}
.sectionFieldItemContent{width: $full-width;float: left;}
.sectionFieldItemContentTxt{float: left;font-family: font("medium");color: $blue-primary;font-size: 1em;line-height: 1.4em;}
.sectionFieldItemContentTxt p, h1, h2, h3, h4, h5, span {font-family: font("medium");color: $blue-primary;font-size: 1em;line-height: 1.4em;}
.sectionFieldItemContentTxt.kn{float: left;font-family: font("kn_medium");color: $blue-primary;font-size: 1.2em;line-height: 2em;margin-bottom: -10px;}
.sectionFieldItemEditContainer{width: 35px;height: $full-width;float: left;position: absolute;right: 0px;top: 0px;}
.sectionFieldItemEditIcon{width: $full-width;float: left;padding-top: 13px;cursor: pointer;}
.sectionFieldItemEditIco{color: $dashboard-grey;float: left;font-size: 1.2em;}
.sectionFieldItemEditIco:hover{color: $navbar-link-hover-bg;@include txtColorHoverAnim();}

.noDataContainer{width: $full-width;float: left;position: relative;border: 2px dashed $blue-primary;border-radius: 15px;padding: 50px 100px;@include flexCenter();overflow: hidden;}
.noDataContainer.hasSectionAction{cursor: pointer;}
.noDataContainer:hover{border: 2px dashed $bubble-primary;@include borderHoverAnim();}
.noDataContainer:hover .noDataContainerInner .noDataContainerIcon .noDataContainerIco{color: $bubble-primary;@include txtColorHoverAnim();}
.noDataContainer:hover .noDataContainerInner .noDataContainerHeadingDescription .noDataContainerHeading .noDataContainerHeadingTxt{color: $bubble-primary;@include txtColorHoverAnim();}
.noDataContainer:hover .noDataContainerInner .noDataContainerHeadingDescription .noDataContainerDescription .noDataContainerDescriptionTxt{color: $bubble-dark-transparent;@include txtColorHoverAnim();}
.noDataContainerInner{width: 700px;float: left;}
.noDataContainerIcon{width: $full-width;float: left;display: flex;justify-content: center;}
.noDataContainerIco{color: $blue-primary;font-size: 5em;float: left;}
.noDataContainerHeadingDescription{width: $full-width;float: left;margin-top: 20px;}
.noDataContainerHeading{width: $full-width;float: left;display: flex;justify-content: center;}
.noDataContainerHeadingTxt{float: left;text-align: center;font-family: font("semibold");color: $blue-primary;font-size: 1.3em;}
.noDataContainerDescription{width: $full-width;float: left;display: flex;flex-direction: column;gap: 7px;margin-top: 10px;}
.noDataContainerDescriptionTxt{float: left;font-size: 0.9em;text-align: center;color: $dashboard-grey;line-height: 1.5em;}
.noDataContainerActions{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;flex-direction: row;}
.noDataContainerActionsInner{float: left;display: flex;flex-direction: row;gap: 15px;min-width: 300px}


/* Add Website - Select Officers Card */

.websiteSelectOfficersListItem{width: 176px;float: left;cursor: pointer;position: relative;@include disableElementSelection();z-index: 9;}
.websiteSelectOfficersListItemOfficerType{width: $full-width;float: left;display: flex;align-items: center;flex-direction: column;margin-top: 10px;}
.websiteSelectOfficersListItemOfficerTypeIcon{float: left;}
.websiteSelectOfficersListItemOfficerTypeIcon{float: left;height: 120px;display: flex;align-items: center;}
.websiteSelectOfficersListItemOfficerTypeImage{width: $full-width;height: 120px;display: flex;justify-content: center;}
.websiteSelectOfficersListItemOfficerTypeImageInner{width: 120px;height: 120px;border: 1px solid $page_bg;border-radius: 10px;overflow: hidden;padding: 5px;}
.websiteSelectOfficersListItemOfficerTypeImageContainer{width: 108px;height: 108px;float: left;border-radius: 5px;background-position: center;background-size: contain;background-repeat: no-repeat;}
.websiteSelectOfficersListItemOfficerTypeIco{float: left;font-size: 3em;color: $border_grey;}
.websiteSelectOfficersListItemOfficerDetails{width: $full-width;float: left;padding: 10px 20px 25px;}
.websiteSelectOfficersListItemOfficerName{width: $full-width;float: left;display: flex;justify-content: center;}
.websiteSelectOfficersListItemOfficerNameTxt{float: left;font-size: 1em;text-transform: capitalize;color: $blue-primary;font-family: font("medium");@include singleLine();}
.mediaLibraryPopUpListItemOfficerRole{width: $full-width;float: left;display: flex;justify-content: center;margin-top: 4px;}
.mediaLibraryPopUpListItemOfficerRoleTxt{float: left;font-size: 0.8em;text-transform: capitalize;color: $dashboard-grey;font-family: font("medium");@include singleLine();}
.mediaLibraryPopUpListItemOfficerStatus{width: $full-width;float: left;display: flex;justify-content: center;margin-top: 13px;}
.mediaLibraryPopUpListItemOfficerStatusTxt{float: left;font-size: 0.85em;text-transform: capitalize;color: $dashboard-grey;font-family: font("medium");@include singleLine();}
.mediaLibraryPopUpListItemOfficerStatusTxt.active{color: $dashboard-green-dark;padding-bottom: 2px;border-bottom: 1px solid $dashboard-green-dark;}
.websiteSelectOfficersListItemSelected{position: absolute;top: 5px;right: 7px;width: 18px;height: 18px;float: left;}
.websiteSelectOfficersListItemSelectedIco{color: $bubble-primary;float: left;font-size: 1.1em;}

.subHeaderColorPicker{width: 170px;float: left;}
.subHeaderColorPicker .react-colorful{width: 170px;height: 170px;}

.clickableCountTable{width: $full-width;float: left;padding-left: 20px;}
.clickableCountTableTxt{width: 40px;height: 40px;@include flexCenter();float: left;background-color: $disabledBg;border-radius: 20px;overflow: hidden;font-size: 0.95em;color: $blue-primary;font-family: font("medium");}

.sectionFieldItemPreviewSection{width: $full-width;float: left;}
.sectionFieldItemPreviewSectionMain{float: left;display: flex;flex-direction: row;gap: 15px;align-items: center;}
.sectionFieldItemPreview{width: 60px;height: 60px;float: left;border-radius: 5px;background: $dashboard-border-bg;@include flexCenter();padding: 4px;}
.sectionFieldItemPreviewIcon{float: left;}
.sectionFieldItemPreviewIco{float: left;font-size: 1.5em;color: $disabledTxt;}
.sectionFieldItemPreviewInner{width: $full-width;height: $full-width;float: left;@include flexCenter();border-radius: 4px;overflow: hidden;}
.sectionFieldItemPreviewImage{max-width: $full-width;max-height: $full-width;float: left;overflow: hidden;}
.sectionFieldItemPreviewImg{max-width: $full-width;max-height: $full-width;float: left;min-height: 30px;min-width: 30px;}
.sectionFieldItemPreviewContent{float: left;display: flex;flex-direction: column;gap: 3px;}
.sectionFieldItemPreviewContentHeading{float: left;}
.sectionFieldItemPreviewContentHeadingTxt{float: left;font-family: font("medium");color: $blue-primary;font-size: 1.1em;line-height: 1.4em;}
.sectionFieldItemPreviewContentSize{float: left;}
.sectionFieldItemPreviewContentSizeTxt{float: left;font-size: 0.8em;font-family: font("medium");color: $dashboard-grey;}