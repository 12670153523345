
.cmsDashboardPopUpContentCrop{float: left;width: $full-width;max-height: 550px;padding: 20px 0px;display: flex;flex-direction: column;align-items: center;}
.cmsDashboardPopUpContentCropImage{max-width: $full-width;max-height: $full-width;float: left;}
.cmsDashboardPopUpContentCropImg{float: left;max-height: 450px !important;max-width: 500px !important;}

.cmsDashboardPopUpContentCropOptions{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;}
.cmsDashboardPopUpContentCropOptionsInner{float: left;display: flex;flex-direction: row;gap: 30px;}
.cmsDashboardPopUpContentCropOptionSlider{float: left;display: flex;flex-direction: row;align-items: center;}
.cmsDashboardPopUpContentCropOptionSliderInput{float: left;}
.cmsDashboardPopUpContentCropOptionSliderInputTxt{float: left;width: 130px;}
.cmsDashboardPopUpContentCropOptionSliderLable{float: left;margin-left: 10px;}
.cmsDashboardPopUpContentCropOptionSliderLableTxt{float: left;font-size: 1em;font-family: font("semibold");text-transform: capitalize;color: $blue-primary;}
.cmsDashboardPopUpContentCropOptionRotate{float: left;display: flex;flex-direction: row;gap: 10px;}
.cmsDashboardPopUpContentCropDownloadCancel{float: left;display: flex;flex-direction: row;gap: 10px;}

.cmsDashboardPopUpContentCreateFolder{float: left;width: $full-width;max-height: 350px;padding: 20px 20px;display: flex;flex-direction: column;align-items: center;}
.cmsDashboardPopUpContentCreateFolder form{width: $full-width;float: left;}

.cmsDashboardPopUpContentUploadMedia{float: left;width: 850px;max-height: 550px;display: flex;flex-direction: column;}
.cmsDashboardPopUpContentUploadMediaInner{width: $full-width;float: left;padding: 20px 20px 0px 20px;}
.cmsDashboardPopUpContentUploadMediaTabsContent{width: $full-width;float: left;}
.cmsDashboardPopUpContentUploadMediaTabs{width: $full-width;float: left;}
.cmsDashboardPopUpContentUploadMediaTabsList{float: left;display: flex;flex-direction: row;gap: 5px;}
.cmsDashboardPopUpContentUploadMediaTabsListItem{float: left;padding: 10px 30px;border-radius: 10px 10px 0px 0px;cursor: pointer;}
.cmsDashboardPopUpContentUploadMediaTabsListItem.active{background: $page_bg;}
.cmsDashboardPopUpContentUploadMediaTabsListItem:hover{background: $page_bg;}
.cmsDashboardPopUpContentUploadMediaTabsListItemTxt{float: left;text-transform: capitalize;}
.cmsDashboardPopUpContentUploadMediaContent{width: $full-width;float: left;background: $page_bg;padding: 10px 0px;}
.cmsDashboardPopUpContentUploadMediaTab{width: $full-width;float: left;max-height: 320px;overflow-y: auto;padding: 10px 20px;}
.cmsDashboardPopUpContentUploadMediaTabSelect{width: $full-width;float: left;}
.uploadMediaPopUpSelectedFilesTableContainer{width: $full-width;float: left;}
.uploadMediaPopUpSelectedFilesTableContainer .cmsDashboardTableContainer .cmsDashboardTable{width: $full-width;overflow: hidden;}

.cmsDashboardPopUpContentUploadMediaActions{width: $full-width;float: left;display: flex;justify-content: flex-end;padding: 20px;}
.cmsDashboardPopUpContentUploadMediaActionsMain{float: left;display: flex;flex-direction: row-reverse;gap: 15px;}


.cmsDashboardPopUpContentAddOfficer{float: left;width: $full-width;padding: 20px 20px;display: flex;flex-direction: column;align-items: center;}
.cmsDashboardPopUpContentAddOfficer form{width: $full-width;float: left;}